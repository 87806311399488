import 'core-js';
import 'regenerator-runtime';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import BootstrapVueIcons from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VeeValidate from 'vee-validate';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import VueCookies from 'vue-cookies';
import Sticky from 'vue-sticky-directive';
import excel from 'vue-excel-export';
import ICS from 'vue-ics';
import VueGtag from 'vue-gtag';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faPaste } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faPen, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faUpDown } from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(CKEditor);

import PortalVue from "portal-vue";
import { Kalendar } from 'kalendar-vue';
Vue.use(PortalVue);
Vue.use(Kalendar);


library.add(faPencilAlt, faCogs, faLock, faLockOpen, faUsers, faShareSquare, faUserCog, faComment, faExternalLinkAlt, faPlus, faUpDown);
library.add(faTrash, faEye, faEyeSlash, faCheckSquare, faPaste, faInfoCircle, faUser, faCopy, faBrain, faShoppingCart, faGripVertical);
library.add(faArrowUp, faArrowDown, faRocket, faGavel, faQrcode, faAt, faPlane, faTrophy, faCheckDouble, faInfo, faBagShopping, faSyncAlt);
library.add(faTableColumns, faColumns, faTriangleExclamation, faRepeat, faCalendarDays, faBars, faBasketShopping, faArrowTrendUp, faPen, faMinus);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(Sticky);
Vue.use(excel);
Vue.use(ICS, { uidDomain: 'www.ehro.app', prodId: 'ehro.app' });
Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  validity: true,
});

Vue.use(VueGtag, {
  config: { id: 'UA-164123199-1' },
}, router);


import Snotify from 'vue-snotify';
import { SnotifyPosition } from 'vue-snotify';
import { SnotifyService } from 'vue-snotify/SnotifyService';

const options2 = {
  toast: {
    position: SnotifyPosition.leftBottom,
    timeout: 2000,
  },
};
Vue.use(Snotify, options2);

declare module 'vue/types/vue' { export interface Vue { readonly $snotify: SnotifyService; } }
// declare module 'vue/types/vue' { export interface Vue { readonly $ics: ICS; } }

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
