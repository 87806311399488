const fr = {
  // FORM BUILDER
  fields: "Champs",
  drag_field: "Glissez un champ dans le formulaire",
  add_volunteer: "+ Bénévole",
  add_event: "+ Événement",
  add_campaign: "+ Campagne",
  form: "Formulaire",
  tooltip_label: "C'est le texte affiché dans le formulaire final",
  choose_type: "Choisissez un type",
  per_participation: "Par participation",
  options: "Options",
  parent_field: "Champ parent",
  no_parent_field: "Aucun champ parent",
  trigger_value: "Valeur déclencheur",
  refresh_fields: "Les champs disponibles ont été rafraîchis.",
  refresh_error: "Une erreur s'est produite lors du rafraîchissement.",
  delete_confirmation:
    'Êtes-vous sûr de vouloir supprimer le champ "{field}" ? Cette action est irréversible.',
  delete_success: 'Le champ "{field}" a été supprimé.',
  delete_error: "Une erreur s'est produite lors de la suppression du champ.",
  save_error: "Une erreur s'est produite lors de la sauvegarde.",
  configure_field: "Configurer le champ",
  option_name: "Nom de l'option",
  confirmation: "Confirmation",
  label: "Libellé",
  country: "Pays",
  birthdate: "Date de naissance",
  phone: "No de téléphone",
  field_name: "Nom du champ",
  field_type: "Type du champ",
  text: "Texte",
  text_multiline: "Text long",
  int: "Nombre",
  date: "Date",
  enum: "Choix unique",
  enum_multiple: "Choix multiple",
  bool: "Oui / Non",
  restriction_enum_as_parent:
    "Seuls les champs de type choix unique peuvent être utilisés comme condition",
  important_information: "ℹ️ Informations importantes",
  restriction_light_fields:
    "La licence Light ne permet pas d’ajouter des champs bénévoles. Vous pouvez cependant créer jusqu’à 3 champs événementiels.",
  restriction_club_fields:
    "La licence Club permet d’ajouter 3 champs bénévoles et 3 champs événementiels maximum.",
  restriction_alert: "⚠️ Restrictions liées à votre licence",
  restriction_volunteer_fields_licence:
    "Vous avez atteint la limite de champs bénévoles autorisés par votre licence.",
  restriction_event_fields_licence:
    "Vous avez atteint la limite de champs événementiels autorisés par votre licence.",

  conditions: "Condition",

  max_part_at_time: "Nombre maximal de participants à la fois",
  cfg_basket: "Activer le panier pour les inscriptions",
  cfg_basket_info: "Impossible avec les inscriptions de groupe",
  menu_badge: "Badges",
  onlyNotFull: "Seulement les tranches non-complètes",
  filter_public: "Filtrer le tableau",
  reg_form_phone_too_short: "Numéro non valide",
  reg_form_phone_use_country_code:
    "Merci d'indiquer l'indicatif international avec +XX ex: +41 112223344",

  sort: "Ordrer par nom",
  montant: "Montant",
  quantity: "Quantité",
  solde: "Solde",
  product_purchased: "Achat",
  manual_adjustment: "Ajustement manuel",
  participation_accepted: "Inscription",
  participation_cancelled: "Désinscription",
  participation_changed: "Modification d’inscription",
  time_slot_changed: "Changement de trache horaire",
  hourly_rate_changed: "Changement du nombre de points",

  point: "point | points",
  buy: "Acheter",
  not_available: "Pas encore disponible",
  portail_shop: "Shop",
  portail_shop_transaction: "Historique des points",
  portail_shop_orders: "Mes achats",
  shop_purchase_confirmed: "Achat confirmé",
  portail_affectation: "Vos affectations",
  registration_info: "",
  registration_error: "Erreur lors de l'inscription",
  filter: "Filtrer",
  reward_category: "Shop: catégorie des tranches",
  code: "Code",
  order_status_awaiting_delivery: "En attente de retrait",
  order_status_delivered: "Remis",
  order_status_cancelled: "Annulé",
  order_cancelled: "Commande annulée",

  copy_filtered_missions: "Copier les missions filtrées",
  no_shift: "Aucune tranche disponible pour cette date",
  remove_all_tags: "Supprimer tous les tags",
  hours_for_day_day_error:
    "Attention, des shifts ne seront plus visible le {date}",
  hours_for_day_full_event: "Appliquer à tous les jours",
  cfg_enable_registrations: "Autoriser les inscriptions",
  private_description: "Description interne",
  cfg_enable_past:
    "Afficher les tranches horaires dans le passé sur le planificateur public",

  jour_small: "j",
  contact: "Contact",
  in_case_of_questions: "En cas de question contactez :",
  general_info: "Informations générales",
  reg_form_email_desc:
    "Nécessaire si vous souhaitez une confirmation et un rappel",
  registration_done: "Inscription enregistrée",
  consult_spam: "Si vous ne recevez pas de confirmation, consultez vos spams",

  order: "Ordre",
  add_tags: "Ajouter des tags",
  remove_tags: "Supprimer les tags",
  remove_tags_text:
    "Tous les tags de toutes les missions sélectionnées seront supprimés",

  show_tag: "Afficher les tags",
  tag: "Tag | Tags",
  tag_public: "Tags publics",
  tag_private: "Tags internes",
  no_item_found: "Aucun élément trouvé",
  choose_tag: "Sélectioner 1 ou plusieurs tags",
  save_first: "Veuillez d'abord sauvegarder",

  x_item_deleted: "{nbr} élément supprimé | {nbr} éléments supprimés",
  x_item_created: "{nbr} élément créé | {nbr} éléments créés",
  x_item_updated: "{nbr} élément mis à jour | {nbr} éléments mis à jour",

  more_dates_here: "{nbr} jours disponibles",

  menu_soon: "À venir",
  soon_title: "À venir",

  menu_com_model: "Modèles",

  all_day: "Jour complet",

  form_event_name: "Nom de l'événement",
  form_event_logo: "Logo",
  event_action_edit: "Modifier",
  event_action_link: "Partager",
  event_action_ranking: "Voir le classement",
  event_action_assign: "Affecter des bénévoles",
  event_action_manage: "Gestion opérationnelle",
  event_action_clone: "Cloner",
  event_action_delete: "Supprimer",
  event_action_plan: "Planifier",
  edit_tab_detail: "Page de détail",

  cal_by_week: "Par semaine",
  cal_by_day: "Par jour",
  cal_display_today: "Afficher aujourd'hui",
  cal_display_volunteers: "Afficher les bénévoles",

  alert_welcome:
    "<b>🎉 Mise à jour 5.0 🎉</b> Consultez le VLOG en bas de page ainsi que les notes de mises à jour !",

  alert_wrong_browser:
    "Mozilla Firefox ou Google Chrome sont recommandés pour l’utilisation du système",

  vol_display_name_nothing: "Ne pas afficher le prénom ni le nom",
  vol_display_name_full: "Afficher le prénom et nom complets",
  vol_display_name_abbreviated: "Abréger le nom de famille",

  switch_org: "Changer d’organisation",
  extend_event: "Extension d'un événement",
  event_target: "Événement à étendre",
  event_extend_button: "Copier dans un autre événement",

  clone_participations: "Cloner les inscriptions des bénévoles",
  clone_participations_info:
    "En cochant cette case, le système copiera aussi les participations des bénévoles dans chacune des tranches horaires",
  clone_participations_categorie:
    "Vous pouvez filtrer les inscriptions qui seront clonées en fonction des catégories des bénévoles (si vous ne cochez rien, tout sera copié)",

  startDate: "Date de début",
  endDate: "Date de fin",
  startTime: "Heure de début",
  endTime: "Heure de fin",

  calendar: "Calendrier",

  list_enable_edit: "Activer édition",
  list_disable_edit: "Vérouiller et sauver",
  age: "Âge",
  timezone: "Fuseau horaire de l'événement",
  timezoneChangeTitle: "Attention",
  timezoneChangeDesc:
    "Votre événement contient des tranches horaires. \
  Le système va adapter toutes les tranches sur le nouveau fuseau horaire",

  complex_event: "Événement complexe",
  complex_event_desc:
    "Les événements complexes permettent plus de configuration",
  complex_select_date: "Sélection des dates",
  complex_period: "Période étendue (génération automatique)",
  complex_period_desc:
    "Dans le cadre d'un événement étendu, vous définissez uniquement la période de votre événement durant laquelle les tranches peuvent être créées.",

  portal_title: "Portail des bénévoles",
  portal_acces_info: "Email requis pour consulter vos affectations",
  portal_acces_btn: "Voir mes affectations",
  portal_print: "Imprimer mon planning",

  portail_goodies: "Vos vouchers",
  portail_display_goodies: "Afficher mes vouchers",
  portail_refresh_goodies: "Rafraichir mes vouchers",
  portail_with_goodies:
    "Super ! Grâce à votre engagement vous avez le droit à {nbr} voucher(s)!",
  portail_with_goodies_no_voucher:
    "Vous n'avez droit à aucun voucher :-(, contactez l'organisateur en cas d'erreur",
  portail_no_goodies:
    "Malheureusement, vous ne remplissez pas les conditions requises pour recevoir des vouchers.",
  portail_show_rules: "Consulter les conditions",
  menu_shop: "Shop",
  menu_voucher_list: "Mes bons",
  menu_voucher_import: "Importation",
  portail_show_howto: "Comment utiliser mes bons ? Rendez-vous ici ",
  menu_shop_product: "Contreparties",
  menu_shop_config: "Configuration",

  occurence: "Occurence",
  serie: "Série",
  delete_serie: "Supprimer toute la série ou uniquement cette occurence ?",
  affectation_recurrente: "Affectation récurrente",
  add_serie: "Ajouter à toute la série ?",

  menu_affectation: "Affectations",
  menu_validations: "À valider",
  message: "Message",

  tag_list: "Liste des tags",
  auto_save: "Sauvegarde automatique",
  adjust_timeframe: "Ajuster la plage horaire du jour",

  status_group_action: "Changer le status par défaut",
  cfg_validation: "Validation des affectations",
  default_validation_status: "Validation par défaut",
  AWAITING_ORGANIZER_VALIDATION: "En attente de confirmation de l'organisateur",
  AWAITING_VOLUNTEER_VALIDATION: "",
  REJECTED_BY_ORGANIZER: "",
  REJECTED_BY_VOLUNTEER: "",
  CANCELLED_BY_ORGANIZER: "",
  CANCELLED_BY_VOLUNTEER: "",
  ACCEPTED: "Validé",

  // VLOG
  menu_vlog: "VLOG",
  vlog_title: "Quelques infos d'EHRO",
  vlog_desc: "Ici nous partageons avec vous quelques infos sur EHRO",

  // Docs
  doc_title: "Tutos",
  doc_latest: "Les dernières nouveautés expliquées",
  doc_general: "Généralités",
  doc_event: "Création d'événements",
  doc_gestion: "Gestion des inscriptions",
  doc_vol: "Gestion des bénévoles",
  doc_comm: "Communiquer et imprimer",
  doc_public: "Les pages publiques et le portail",

  // Release notes
  release_title: "Quoi de neuf ?",
  release_latest: "Dernières versions",
  release_old: "Versions précédentes",
  release_desc: "Cliquez pour plus de détail",
  release_v5: "Version 5",
  release_v4: "Version 4",
  release_v3: "Version 3",
  release_v2: "Version 2",
  release_v1: "Version 1",
  release_v0: "Version BETA",

  // Portail
  portail_quick_stats: "Vos statistiques",
  portail_comm: "Communications",
  portail_no_communication: "Aucune communication à afficher",
  portail_upcoming_shifts: "Prochaines affectations",
  portail_group_upcoming_shifts: "Prochaines affectations en groupe",
  portail_solo_upcoming_shifts: "Vos prochaines affectations",
  portail_awaiting_org_validation_shifts:
    "Affectations en attente de confirmation",
  portail_awaiting_org_validation_desc:
    "L'organisateur doit valider votre inscription. \
  Un email de confirmation vous sera envoyé lors de la validation.",
  portail_rejected_org_validation_shifts:
    "Affectations rejetées par l'organisateur",

  portail_no_upcoming_shifts: "Aucune affectation à venir",
  portail_hide_old_shifts: "Masquer les anciennes affectations",
  portail_load_old_shifts: "Afficher les anciennes affectations",
  mail_sentat: "Envoyé à",
  mail_subject: "Sujet",
  mail_sender: "Expéditeur",
  mail_event: "Événement",
  stats_group: "En groupe",

  // 2FA
  devices_title: "Authentification à deux facteurs",
  devices_active: "Méthode activée | Méthodes activées",
  devices_add: "Ajouter une méthode d'authentification à deux facteurs",
  devices_email: "Par email",
  devices_qrcode: "Par générateur de codes",
  devices_backup: "Par code de secours",
  devices_nb_backup_codes_left:
    "{nbCodes} code restant | {nbCodes} codes restants",
  devices_qrcode_howto:
    "Utilise ton application favorite de double authentification, scan le QRCode ci-dessus et entre le code de vérification.",
  devices_code: "Code",
  devices_qrcode_setup: "Configurer le générateur de codes",
  devices_delete:
    "Voulez-vous vraiment supprimer cette méthode d’authentification?",
  devices_email_setup:
    "Un email contenant un code a été envoyé à <strong>{currentEmail}</strong>," +
    "  merci d'introduire le code de confirmation ci-dessous dans un délai de 5 minutes.",
  devices_will_logout: "Vous allez être déconnecté",
  devices_use_backup: "Utilise un de tes codes de sauvegardes",
  backup_renew: "Regénérer",

  // 3.5
  sure: "Tu confirmes ?",

  // 3.4
  complete_calendar: "Calendrier complet",
  goto_volunteer_space: "Accès bénévoles",

  //3.1
  cfg_enable_group: "Activer les inscriptions de groupe",
  reg_form_groupsize_label: "Indiquez un nombre de membre (entre 1 et {nbr})",
  reg_form_mendatory_groupsize:
    "Introduire un nombre de personne entre 1 et {nbr}",
  reg_individual: "Individuelle",
  reg_group: "Groupe",
  reg_type: "Quel type d'inscription ?",
  reg_group_unable:
    "Inscription en groupe impossible car il ne reste pas assez de place dans cette tranche",
  my_group_registration: "Mes affectations de groupe",
  group_name: "Nom du groupe",
  groupe_size: "Taille du groupe",
  cfg_hide_full_slot: "Masquer les tranches complètes du tableau",
  campaign_fields: "Champs",
  add_field: "Ajouter un champ",
  form_mandatory_field: "Obligatoire",

  //3.0
  x_slot_deleted: "{nbr} tranche supprimée | {nbr} tranches supprimées",
  x_slot_created: "{nbr} tranche créée | {nbr} tranches créées",
  x_slot_updated: "{nbr} tranche mise à jour | {nbr} tranches mises à jour",
  tmp_add_slot: "Modification ajouté à la liste d'attente",
  update_needed:
    "Modifications en attente de sauvegarde, pour annuler veuillez rafraîchir la page",
  event_schedule_enable_autosave_title:
    "Sauvegarde automatique des modifications",
  event_schedule_enable_copypaste:
    "Activer le copier-coller (attention, la sauvegarde automatique sera désactivée)",
  reg_form_birthdate_desc: "Nécessaire pour certaines tâches / accréditations",
  tooltip_simple_choose:
    "Merci de choisir <strong>{nbr}</strong> option | Merci de choisir <strong>{nbr}</strong> options",
  tooltip_min_choose:
    "Merci de choisir au minimum <strong>{min}</strong> option | Merci de choisir au minimum <strong>{min}</strong> options",
  tooltip_max_choose:
    "Merci de choisir au maximum <strong>{max}</strong> option | Merci de choisir au maximum <strong>{max}</strong> options",
  tooltip_minmax_choose:
    "Merci de choisir au minimum <strong>{min}</strong> option(s) et au maximum <strong>{max}</strong> options",
  tooltip_no_limit_choose: "Plusieurs choix possible",
  invalid_form:
    "Le formulaire contient des erreurs, merci de contrôler vos réponses !",
  thankyou: "Merci",
  form_failure_too_many: "Veuillez cocher au maximum {nbr} choix",
  form_failure_too_less: "Veuillez cocher au moins {nbr} choix",
  registrations: "Inscription | Inscriptions",
  registrations_in_progress: "Inscription en cours | Inscriptions en cours",
  status_closed: "Terminé | Terminés",
  status_closing: "Terminer",
  campaign: "Campagne | Campagne | Campagnes",
  no_campaign: "Aucune campagne trouvée",
  campaign_confirm_delete: "Êtes-vous sûr de vouloir supprimer la campagne ?",
  create_campaign: "Créer une campagne",
  edit_campaign: "Modifier une campagne",
  new_campaign: "Nouvelle campagne",
  msg_cmp_created: "Campagne créée",
  msg_cmp_saved: "Campagne sauvegardée",
  form_cmp_name: "Nom de la campagne",
  form_cmp_name_missing: "Un nom des obligatoire",
  form_cmp_desc: "Description de la campagne",
  form_cmp_desc_missing: "Une description est obligatoire",
  form_cmp_resp: "Nom de la personne de contact pour les bénévoles",
  form_cmp_resp_missing: "Entrer le nom de la personne de contact",
  form_cmp_mail: "Email du responsable",
  form_cmp_mail_missing: "Un e-mail est obligatoire",
  profile: "Profil",
  register: "S'inscrire",
  update: "Valider les modifications",
  createdAt: "Inscription faite le",
  registration_confirm_delete:
    "Êtes-vous sûr de vouloir supprimer votre inscription ?",
  reg_table_createdAt: "Date inscription",
  reg_table_modifiedAt: "Dernière modification",
  vol_prof_listing_campaign: "Inscriptions aux campagnes",
  profile_editing: "Modifier",
  cmp_reg_confirm_delete:
    "Êtes-vous sûr de vouloir supprimer l'inscription sélectionnée ? Ceci ne supprimera pas le bénévole.",
  cmp_regs_confirm_delete:
    "Êtes-vous sûr de vouloir supprimer les {nbr} inscriptions sélectionnées ? Ceci ne supprimera pas les bénévoles.",
  reg_modifed: "Inscription modifiée",
  mail_cmp_name: "Nom de la campagne",
  event_schedule_enable_copypaste_position:
    "Activer le copier/coller par mission",
  event_schedule_enable_copypaste_sector:
    "Activer le copier/coller par catégorie",
  default_vol_number:
    "Nombre de volontaire par défaut dans les nouvelles tranches",
  ////

  //2.5
  slot_deleted: "Tranche supprimée",
  slot_updated: "Tranche mise à jour",
  vol_switch_ok: "Changement terminé avec succès",
  vol_switch_categ: "Changement de catégorie",

  no_public_event:
    "Nous ne recherchons aucun héros pour le moment ! Revenez plus tard.",
  no_public_event_corporate:
    "Toutes les plages horaires sont actuellement occupées.",
  no_shift_found:
    "Nous avons bien cherché mais il n'y a pas de tranches horaires libres correspondant à votre demande.",
  public_list_event: "Liste des événements en cours",
  public_contact: "Page de contact",

  // EMCC
  address: "Adresse",
  town: "Ville",
  npa: "NPA",
  vol_list_category: "Catégorie",
  // Public page
  menu_org_page: "Ma page publique",
  cfg_org_public_page: "Page publique de votre organisation",
  cfg_show_on_public_page: "Afficher sur ma page publique",
  cfg_show_on_home_calendar: "Publier mon évènement sur le calendrier EHRO",

  // MANAGER
  pwd: "Mot de passe",
  manager_acces: "Accès responsable",
  manager_filter: "Filtres",
  manager_affectations: "Affectations",
  manager_print_assi: "Imprimer les affectations",
  manager_print_repas: "Imprimer les repas",
  espace_manager: "Espace manager",
  manager_acces_info:
    "Connectez-vous pour consulter les affectations de vos bénévoles",
  manager_acces_btn: "Se connecter",
  pwd_mandatory: "Mot de passe obligatoire",
  manager_error: "Erreur de connexion, contrôler vos informations",
  /** ********************** */

  // NEW 2.5
  menu_campaign: "Campagnes",
  menu_my_campaigns: "Mes campagnes",
  field_form_category: "Catégorie",

  // NEW 2.4
  vol_confirm_delete:
    "Êtes-vous sûr de vouloir supprimer le volontaire sélectionné ? Ceci supprimera aussi son(ses) affectation(s).",
  vols_confirm_delete:
    "Êtes-vous sûr de vouloir supprimer les {nbr} volontaires sélectionnés ? Ceci supprimera aussi leur(s) affectation(s).",
  vol_delete_ok: "Suppression terminée avec succès",
  is_member: "Est membre de l'association",
  is_member_explenation:
    "uniquement modifiable par vous, permet de séparer vos membres des bénévoles externes",
  send_resume_to_me: "Recevoir un email de confirmation",

  // NEW 2.3
  menu_history_mail: "Historique",
  com_sender: "Expéditeur",
  com_nbr_rec: "Nombre de destinataire",

  // NEW 2.1
  vol_list_import: "Import",
  vol_list_export: "Export",
  vol_list_export_cross_events: "Export d’affectations",
  vol_list_export_cross_events_intro:
    "Cet export contient toutes les personnes bénévoles qui ont au moins une tranche horaire parmi les jours et les événements sélectionnés, ainsi que les tranches horaires qui leurs sont assigné‧e‧s.",
  vol_list_export_cross_events_instructions:
    "Vous devez sélectionner au moins un événement et une date.",
  vol_list_export_btn: "Exporter",
  vol_list_tools: "Outils",
  vol_actions_text: "Sélectionner des volontaires pour activer les actions",
  vol_fusion_btn: "Fusionner",
  vol_fusion_title: "Fusionner les volontaires",
  vol_fusion_ok: "Fusion terminée avec succès",
  vol_list_filters: "Filtres",
  vol_edit_participation_to_all:
    "Appliquer à toutes les inscriptions de ce volontaire pour cet événement ?",
  vol_list_refresh: "Actualiser",
  vol_list_display_no_part: "Aucune inscription",
  vol_list_filter_date: "Années",
  vol_list_minimum_filter: "Minimum 1",
  vol_send_email: "Envoi d'email",
  vol_list_invert: "Inverser les filtres (voir vidéo dans documentation)",
  vol_list_andlogic:
    "Logique du ET au lieu du OU (voir vidéo dans documentation)",
  vol_list_all: "Tout sélectionner",
  vol_list_deselect: "Désélectionner",

  // NEW 2.2
  custom_field_form: "Champs personnalisé",
  field_form_title: "Titre du champs",
  field_form_mendatory_title: "Titre obligatoire",
  field_form_type: "Type du champs",
  field_form_list: "Valeurs possibles",
  field_form_mendatory_list: "Obligatoire",
  field_form_list_info: "Séparer les valeurs par ;",
  field_type_txt: "Champs de texte",
  field_type_yesno: "Oui / Non",
  field_type_list: "Liste déroulante",
  field_form_mandatory: "Rendre obligatoire",
  field_form_grouped: "Réponse groupée",
  field_form_grouped_info:
    "Dans le cas d'une inscription de groupe, une seule réponse est requise pour l'entier du groupe",
  field_form_select_value: "Sélectionnez une option",
  yes: "Oui",
  no: "Non",

  // DOWN
  down_title: "Maintenance",
  down_subtitle: "Mise à jour en cours",
  down_text:
    "Merci de revenir plus tard, nous travaillons actuellement sur le site",

  // 404
  http404_subtitle: "Oops! Vous êtes perdu.",
  http404_text: "La page que vous avez demandée n'existe pas.",

  // GENERAL
  loading: "Chargement ...",
  prev: "Précédent",
  next: "Suivant",
  delete: "Supprimer",
  deleted: "supprimé",
  cancel: "Annuler",
  erease: "Effacer",
  valid: "Valider",
  save: "Sauver",
  error: "Erreur",
  success: "Succès",
  close: "Fermer",
  confirm: "Confirmer",
  send: "Envoyer",
  msg_enter_number: "Entrer un nombre",
  add: "Ajouter",
  edit: "Editer",

  // EVENT OBJECT
  organization: "Organisateur | Organisateurs",
  event: "Événement | Événement | Événements",
  sector: "Secteur | Secteurs",
  category: "Catégorie | Catégories",
  mission: "Mission | Missions",
  choose_event: "Choisir un événement",
  no_event: "Aucun événement trouvé",

  // PEOPLE
  firstName: "Prénom",
  lastName: "Nom",
  email: "Email",
  password: "Mot de passe",

  // MANAGER
  manager_confirm_delete: "Êtes-vous sûr de vouloir supprimer le manager ?",

  // NO EVENT SCREEN
  no_event_txt: "Créer un événement pour accéder aux outils",

  // NO ORGANIZATION
  no_session:
    "Votre session a expiré. Veuillez vous déconnecter depuis le menu utilisateur en haut à droite et vous reconnecter.",

  // DASHBOARD {{ $t('cancel') }}
  dashboard: "Tableau de bord",
  dashboard_welcome: "Bienvenue sur EHRO 2.0",
  dashboard_slot: "Tranche | Tranches",
  dashboard_vol: "Bénévole | Bénévoles",
  dashboard_hour: "Heure | Heures",
  last_registrations: "Dernières inscriptions",
  progress_infos: "Tous les événements en cours confondus",
  progress_title: 'Plages horaires complètes sur les événements "EN COURS"',
  dashboard_presentation: "Présentation vidéo de EHRO 2.0",

  // MESSAGE
  msg_day_copy_successfully: "Jour correctement copié",
  msg_delete_successfully: "{obj} correctement supprimé",
  msg_edit_successfully: "{obj} correctement modifié",
  msg_edit_fail: "Erreur lors de la modification",
  msg_moving_successfully: "{obj} correctement déplacé",
  msg_register_success: "Inscription enregistrée !",
  msg_register_success_detail: "Inscription enregistrée pour {name} !",
  msg_register_fail: "Erreur lors de l'inscription",
  msg_mail_success: "{nbr} mails envoyés",
  msg_mail_fail: "Echec de l'envoi",

  // TABLE COLUMN TITLE
  date: "Date | Dates",
  name: "Nom",
  description: "Description",
  required_vol: "Bénévoles requis",
  classification: "Classification",
  status: "Statut",
  action: "Action",
  actions: "Actions",

  // EVENT STATUS
  status_draft: "Brouillon | Brouillons",
  status_published: "Publié | Publiés",
  status_publishing: "Publier",
  status_archive: "Archive | Archives",
  status_archiving: "Archiver",
  status_change_to_draft: "Événement en mode brouillon",
  status_change_to_published: "Événement publié",
  status_change_to_archive: "Événement archivé",

  // USER
  profil: "Profil",
  settings: "Paramètres",
  logout: "Se déconnecter",
  paiement: "Paiement | Paiements",
  options: "Options",
  profil_account: "Infos du compte",
  profil_org_title: "Nom de l'organisateur",
  profil_update_success: "Profil correctement mis à jour",
  error_try_again: "Erreur, veuillez réessayer",
  profil_reset_password_button: "Réinitialiser",
  profil_reset_password_title: "Réinitialiser le mot de passe",
  profil_reset_password_info:
    "Réinitialisation de mot de passe : contrôlez vos emails",
  profil_reset_password_text:
    "Un mail sera envoyé à {email} avec un lien sécurisé permettant la modification du mot de passe.",
  profil_change_logo: "Changer le logo",

  // VOLUNTEER
  volunteer: "Volontaire | Volontaire | Volontaires",

  // VOLUNTEER PROFILE
  vol_prof_title: "Profil : {name}",
  vol_prof_filter: "Filtre",
  vol_prof_id: "ID",
  vol_prof_create: "Créé le",
  vol_prof_filter_event_for: "Pour",
  vol_prof_filter_all_event: "Tous les événements confondus",
  vol_prof_comment: "Commentaire",
  vol_prof_listing: "Listing des affectations",
  vol_prof_listing_filter: "Uniquement pour l'événement : {event}",
  vol_prof_print_plan: "Imprimer le plan de {name} pour {event}",
  vol_prof_slots: "tranche | tranches",
  vol_prof_hours: "heure | heures",

  // VOLUNTEER REGISTRATION FORM
  reg_form_new: "Nouvelle inscription",
  reg_form_existing: "Bénévole existant",
  reg_form_email: "Email",
  reg_form_born: "Date de naissance",
  reg_form_tel: "Tél.",
  reg_form_tel_desc: "Votre numéro ne sera connu que de l'organisateur",
  reg_form_patisserie: "Patisserie",
  reg_form_patisserie_info:
    "Si vous souhaitez apporter une patisserie pour nos ventes :-)",
  reg_form_tshirt: "Taille du t-shirt",
  reg_form_tshirt_info: "Merci de choisir une taille (unisexe)",
  reg_form_reference: "Membre de référence",
  reg_form_reference_info:
    "Si vous représentez un membre de notre société, merci d'indiquer son nom",
  reg_form_edit_title: "Modification",
  reg_form_edit_text:
    "Uniquement les questions spécifiques à cette inscription sont modifiable",
  reg_form_delete_title: "Supprimer : {name}",
  reg_form_delete_text: "Êtes-vous sûr de vouloir effacer l'inscription ?",
  customfield_form_delete_text: "Êtes-vous sûr de vouloir effacer le champs ?",
  reg_form_confirm_email: "Confirmation email",
  reg_form_mendatory_firstname: "Un prénom est obligatoire",
  reg_form_mendatory_lastname: "Un nom est obligatoire",
  reg_form_mendatory_email: "Un email valide est obligatoire",
  reg_form_mendatory_email_confirm: "Doit être identique à l'adresse indiquée",
  reg_form_mendatory_phone: "Un numéro valide est obligatoire",
  reg_form_mendatory_address: "Une adresse est obligatoire",
  reg_form_mendatory_town: "Merci d'indiquer la ville",
  reg_form_mendatory_npa: "Obligatoire",
  reg_form_policy_accept: "J'accepte la",
  reg_form_policy_title: "politique de confidentialité",
  reg_form_existing_info:
    "Cliquer sur un bénévole pour le sélectioner. Sélection multiple possible.",

  // LISTING DES VOLONTAIRES
  vol_list_nbr: "Nombre de résultats",
  vol_list_xls: "Télécharger en format EXCEL",
  vol_list_name: "Nom",
  vol_list_email: "Email",
  vol_list_phone: "Tél.",
  vol_list_born: "Date de naissance",
  vol_list_patisserie: "Patisserie",
  vol_list_tshirt: "T-Shirt",

  // VOL SCHEDULING
  vol_schedule_active: "Activer le formulaire d'inscription au clic",
  event_schedule_reduce: "Réduire toutes les missions par défaut",
  event_schedule_save_title: "Désactiver la sauvegarde automatique",
  event_schedule_save_info: '! Ne pas oublier de cliquer sur "Valider" !',

  // GESTION DES INSCRIPTIONS
  registration: "Inscription | Inscriptions",
  reg_management: "Gestion des inscriptions",
  reg_status: "Etat des inscriptions",
  reg_planning: "Planning",
  reg_full: "Planning complet",
  reg_listing: "Listing",
  reg_listing_title: "Listing des volontaires",
  reg_resume: "Résumé",
  reg_resume_title: "Résumé global",
  reg_communication: "Communication",
  reg_list_patisserie: "Listing des patisseries",
  reg_list_ref_member: "Membres de référence",
  reg_search: "Recherche",
  reg_table_detail: "Détail",
  reg_table_slots: "Tranches",
  reg_table_hour: "Heures",
  reg_table_email: "Email",

  // MENUS
  create_event: "Créer un événement",
  menu_draft: "Brouillons",
  menu_draft_subtitle: "Evénement brouillon | Evénements brouillons",
  menu_current: "En cours",
  menu_event: "Mes événements",
  menu_archive: "Mes archives",
  menu_communication: "Communication",
  menu_send_mail: "Envoi d'emails",
  menu_printing: "Impression | Impressions",
  menu_planning: "Planning",
  menu_planning_per_volunteer: "Plannings individuel",
  menu_bon: "Bons",
  menu_order_tshirt: "Commande t-shirts",
  menu_info: "Infos",
  menu_contact: "Contact",
  menu_documentation: "Documentation",
  menu_integrations: "Intégrations",

  menu_bugs: "Bugs connus",
  menu_features: "Nouveautés",
  menu_volunteer: "Bénévole",
  menu_my_volunteers: "Mes bénévoles",
  menu_my_categories: "Mes catégories",
  menu_public_site: "Site publique",
  menu_manager_site: "Site pour manager",
  menu_participations_export: "Export d’affectations",
  menu_participations_overview: "Vue d’ensemble",
  menu_volunteers_import: "Importation",

  // EDIT EVENT
  edit_event: "Modification d'un événement",
  new_event: "Nouvel événement",
  edit_tab_general: "Général",
  edit_tab_category: "Missions",
  edit_tab_scheduler: "Planification",
  edit_tab_config: "Configuration",
  edit_tab_pro: "Validation des inscriptions",
  edit_tab_restriction: "Règles de restriction",
  edit_tab_actions: "Actions",
  edit_tab_communication: "E-mail de confirmation",
  edit_tab_checkup: "Contrôles",
  msg_event_created: "Événement créé",
  msg_event_saved: "Événement sauvegardé",
  event_confirm_delete: "Êtes-vous sûr de vouloir supprimer l'événement ?",

  // EDIT EVENT : GENERAL
  form_event_name: "Nom de l'événement",
  form_event_name_missing: "Un nom est obligatoire",
  form_event_desc: "Description",
  form_event_teaser: "Texte d'intro",
  form_event_desc_missing: "Une description est obligatoire",
  form_event_teaser_len: "Maximum 1500 caractères",
  form_event_resp: "Nom de la personne de contact pour les bénévoles",
  form_event_resp_missing: "Entrer le nom de la personne de contact",
  form_event_mail: "Email du responsable",
  form_event_mail_missing: "Un e-mail est obligatoire",
  form_event_mail_error: "Entrez une adresse e-mail valide",
  form_event_date: "Dates",
  form_event_date_missing: "Au moins une date est requise",
  participations_rankings: "Classements de bénévoles",
  form_event_website: "Site web",
  form_event_website_missing:
    "Doit commencer avec http:// (exemple http://www.ehro.app ou http://ehro.app)",
  form_event_images: "Photo (max 10, JPG/JPEG, < 10Mb)",
  error_image_format: "Uniquement le format JPG/JPEG autorisé",
  error_image_size: "Fichier trop volumineux",
  error_images_number: "Maximum 10 images",

  // DATEPICKER
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",

  // EDIT EVENT : CATEGORY
  no_category: "Divers (sans catégorie)",
  add_sector: "Entrer une catégorie",
  add_position: "Entrer une mission",
  add_title: "Entrer un titre",
  msg_warning_delete_slot:
    "Attention cette catégorie contient des tranches horaires... Tout sera supprimé.",

  // EDIT EVENT : SCHEDULER
  copy: "Copier",
  copy_all_slots: "Copier toutes les tranches horaire du jour",
  paste: "Coller",
  zoom: "Zoom",

  // EDIT SLOT
  slot_nbr_volunteer: "# de volontaires",
  slot_desc: "Description",

  // EDIT CREATE SLOT
  slot_nbr_vol: "Nombre de volontaires",
  slot_duration: "Durée (en heure)",
  create: "Créer",
  update: "Modifier",

  // EDIT EVENT : SET UP TIMELINE
  hours_for_day: "Plage horaire pour le",
  start_hour: "Heure de début",
  end_hour: "Heure de fin",

  // EDIT EVENT : CONFIGURATION
  upload_logo: "Charger un logo",
  modify_logo: "Changer le logo",
  cfg_public: "Configuration de la page publique",
  cfg_event: "Configuration de l'événement",
  cfg_display_volunteer_name: "Affichage du nom des volontaires",
  cfg_close_position_by_default: "Réduire toutes les missions par défaut",
  cfg_send_me_copy: "M'envoyer une copie par email de chaque inscription",
  cfg_force_confirm_email: "Forcer l'envoi d'un email de confirmation",
  cfg_form: "Configuration du formulaire",
  cfg_mail_mandatory: "Email obligatoire",
  cfg_ask_for_phone: "Demander un numéro de téléphone",
  cfg_phone_mandatory: "Téléphone obligatoire",
  cfg_ask_for_t_shirt: "Demander une taille de t-shirt",
  cfg_ask_for_t_shirt_info:
    "Si vous activez les inscriptions de groupe, utilisez un champs libre",
  cfg_shirt_size_mandatory: "Taille obligatoire",
  cfg_ask_for_ref_member: "Demander un nom de membre de référence",
  cfg_ask_for_patisserie: "Demander une patisserie",
  cfg_confirm_mail: "Configuration de l'email de confirmation",
  cfg_ask_for_born_date: "Demander une date de naissance",
  cfg_ask_for_address: "Demander l'adresse postale",
  cfg_address_mandatory: "Adresse obligatoire",
  cfg_free_field_title: "Entrer le titre du champs",
  cfg_ask_for_free_field_1: "Ajouter un champs libre",
  cfg_ask_for_free_field_2: "Ajouter un second champs libre",
  add_option: "Ajouter une option",
  cfg_special: "Configurations spéciales",
  cfg_display_as_calendar: "Affichage sous forme de calendrier",
  cfg_allow_shift_creation_by_volunteer:
    "Autoriser la création de tranches par les volontaires",

  // EDIT EVENT : ACTIONS
  public_page: "Page publique de l'événement",
  test: "Tests",
  mail_sending: "Envoi d'emails",
  mail_send_to_test: "Envoyer un mail de test à l'adresse de l'événement",
  print_planning: "Imprimer les plannings",
  print_t_shirts: "Imprimer la liste des tailles des t-shirt",
  print_patisserie: "Imprimer la liste des patisseries",
  print_bons: "Imprimer les bons",
  msg_mail_ok: "Mail envoyé avec succès à",
  msg_copy_link_ok: "Le lien a été copié avec succès",
  msg_copy_link_error: "Oops, un problème est survenu",
  others: "Autres",
  report_group: "Rapport de travail / groupe",
  report_member: "Rapport de travail / membre",
  clean_registrations: "Effacer tous les volontaires",

  // ARCHIVE EVENT
  event_archive: "Événement archivé | Événements archivés",
  path_cloning: "Cloning",
  event_copy: "Clonage d'un événement",
  event_copy_prefix: "[CLONE]",
  event_copy_old_dates: "Anciennes dates",
  event_copy_new_dates: "Nouvelles dates",
  event_copy_button: "Cloner",
  msg_event_copied: "Événement cloné",

  // MAIL
  mail_edit: "Modification",
  mail_preview: "Prévisualisation de l'email",
  mail_text: "Texte",
  mail_tags: "Liste des tags",
  mail_vol_name: "Nom complet du volontaire",
  mail_vol_first_name: "Prénom du volontaire",
  mail_event_name: "Nom de l'événement",
  mail_resp_name: "Nom du responsable de l'événement",
  mail_resp_mail: "Email du responsable de l'événement",
  mail_dates: "Dates de l'événement",
  mail_fields:
    "Résumé des inscriptions, avec les champs du formulaire d’inscription",
  mail_resume:
    "Résumé de/des inscriptions du volontaire (texte automatiquement adapté si plusieurs inscriptions",
  mail_bold: "pour la mise en gras",
  mail_italic: "pour la mise en italique",
  mail_link: "pour créer un lien",
  mail_links: "pour un lien cliquable avec un titre",
  mail_formatting: "Mise en forme",
  mail_target_category: "Cibler une catégorie",
  mail_target_position: "Cibler un poste",
  mail_target_everyone: "Tout le monde",
  mail_msg_are_you_sure:
    "Êtes-vous certain d'envoyer le mail à tous les bénévoles ?",
  mail_choose_template: "Choix du modèle de mail",
  mail_template_neutral: "Neutre",
  mail_template_reminder: "Rappel",
  mail_template_thanks: "Remerciements",
  mail_send_me_sample: "M'envoyer un exemple",
  mail_send_to_volunteers: "Envoyer à tous les volontaires",
  mail_confirm_title: "Confirmation d'envoi",
  mail_formatting: "Mise en forme",
  text: "texte",
  title: "Titre",

  // PDF
  pdf_title_plan: "Planning des bénévoles",
  pdf_filename_plan: "Planning-{name}.pdf", // PDF FILE NAME : NO SPACE - NO ACCENT
  pdf_title_tshirt: "Liste t-shirts",
  pdf_filename_tshirt: "TShirts-{name}.pdf", // PDF FILE NAME : NO SPACE - NO ACCENT
  pdf_title_patisserie: "Liste des patisseries",
  pdf_filename_patisserie: "Patisseries-{name}.pdf", // PDF FILE NAME : NO SPACE - NO ACCENT
  pdf_filename_bon: "Bons-{name}.pdf", // PDF FILE NAME : NO SPACE - NO ACCENT
  pdf_filename_bon_custom: "Bons-Perso-{name}.pdf", // PDF FILE NAME : NO SPACE - NO ACCENT
  pdf_filename_bon_all: "Bons-AllInclusive-{name}.pdf", // PDF FILE NAME : NO SPACE - NO ACCENT

  // PRINTING PLANNING
  print_config: "Configurer les détails de l'impression",
  print_dates: "Choix des dates",
  print_sector_position: "Afficher la description des catégories et postes",
  print_slot: "Afficher la description des tranches horaires",
  print_other_fileds: "Autres champs",
  print_display_mail: "Afficher l'adresse mail",
  print_display_phone: "Afficher le numéro de téléphone",
  print_display_tshirt: "Afficher la taille du T-Shirt",
  print_display_patisserie: "Afficher la patisserie",
  print_generate_pdf: "Générer le PDF",
  print_zip_file:
    "Télécharge 1 fichier PDF / bénévoles soit un total de {nbr} fichiers",

  // PRINTING BONS
  print_custom: "Personnalisés",
  print_all_inclusive: "All inclusive",
  print_price: "Tarif horaire",
  print_round: "Arrondi",
  print_hour: "A l'heure",
  print_half_hour: "A la 1/2 heure",
  print_no_rounded: "Pas d'arrondi",
  print_number_hours: "Nombre d'heures",
  print_name_per_line: "Entrer un nom par ligne",

  // PRINTING T-SHIRT
  t_shirt_not_activated:
    "L'option des t-shirts n'a pas été activé pour cet événement.",
  listing_t_shirt: "Listing des t-shirts",

  // PRINTING PLANNING PERSO
  print_perso_title: "Planning d'engagement",
  print_perso_subtitle: "Événement : {name}",
  print_perso_info: "Informations",
  print_perso_resume: "Résumé",
  print_perso_resume_time: "Vous travaillez un total de {nbr} heures",
  print_perso_resume_slots: "Réparti sur {nbr} tranches horaires",
  print_perso_affectations: "Affectations - {date}",
  print_perso_profil: "Profil : {name}",

  // CONTACT
  contact_subtitle: "Questions / remarques / bugs",
  contact_info: "N'hésitez pas à contacter l'équipe de ehro",
  contact_send_mail: "Envoyer un mail",

  // ERROR
  error_no_volunteer_required:
    "Votre événement ne contient aucune tranche horaire ou ne nécessite aucun volontaire",
  error_create_slot_over_range:
    "Impossible de dépasser +10:00, veuillez créer un nouveau jour",
  error_move_range_over_slot:
    "Impossible de modifier la plage horaire du jour car des tranches ne seront plus visibles",
  error_sending_mail: "Echéc de l'envoi à",
  error_default_msg: "Une erreur est survenue",

  // REGISTER FORM
  send_resume_to_volunteer: "Envoyer l'email de confirmation au volontaire",
};

export default fr;
