import { VolunteerModel } from './VolunteerModel';
import { TimeSlotModel } from './TimeSlotModel';
import { CustomAnswerModel } from './CustomAnswerModel';
import { EventModel } from './EventModel';
import { DateTime } from 'luxon';
import { EventDayModel } from './EventDayModel';
import { event } from 'vue-gtag';

export enum ParticipationType {
  All = 'all',
  Solo = 'solo',
  Group = 'group',
}

export enum ParticipationStatus {
  AWAITING_ORGANIZER_VALIDATION = 0,
  AWAITING_VOLUNTEER_VALIDATION = 1,
  REJECTED_BY_ORGANIZER = 2,
  REJECTED_BY_VOLUNTEER = 3,
  CANCELLED_BY_ORGANIZER = 4,
  CANCELLED_BY_VOLUNTEER = 5,
  ACCEPTED = 6,
}

export class ParticipationModel {

  public static copy(part: ParticipationModel): ParticipationModel {
    const cp = new ParticipationModel();
    cp.id = part.id;
    cp.nbParticipants = part.nbParticipants;
    cp.groupName = part.groupName;
    return cp;
  }

  public static getStatusName(status: ParticipationStatus): string {
    switch (status) {
      case 0:
        return "AWAITING_ORGANIZER_VALIDATION";
      case 1:
        return "AWAITING_VOLUNTEER_VALIDATION";
      case 2:
        return "REJECTED_BY_ORGANIZER";
      case 3:
        return "REJECTED_BY_VOLUNTEER";
      case 4:
        return "CANCELLED_BY_ORGANIZER";
      case 5:
        return "CANCELLED_BY_VOLUNTEER";
      case 6:
        return "ACCEPTED";
      default:
        return '';
    }
  }


  public id: number | null = null;
  public createdAt: Date | null;

  // Group
  public nbParticipants: number = 1;
  public groupName: string = '';

  // Lien avec l'event
  public eventId: number | null = null;
  public eventName: string = '';

  // Lien avec le volunteer
  public volunteerId: number | null = null;
  public firstName: string = '';
  public lastName: string = '';
  public email: string;
  public phoneNumber: string;
  public tshirt: string;
  public bornDate: Date | null = null;

  // Lien avec le slot
  public timeSlotId: number;
  public timeSlot: TimeSlotModel;

  public validationStatus: ParticipationStatus;

  // Question spécifiques
  public customAttributeValues: { attribute: number; participation: number | null; value: number | string | boolean | Date }[] = [];

  public get _rowVariant() {
    if (this.validationStatus === ParticipationStatus.AWAITING_ORGANIZER_VALIDATION) {
      return 'warning';
    }
    return '';
  }

  constructor({
    id = null,
    timeSlotId = -1,
    volunteerId = null,
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    tshirt = '',
    eventId = null,
    createdAt = null,
    timeSlot = new TimeSlotModel(),
    nbParticipants = 1,
    groupName = '',
    eventName = '',
    validationStatus = ParticipationStatus.ACCEPTED,
    eventTimeZone = '',
  }: {
    id?: number | null,
    timeSlotId?: number,
    volunteerId?: number | null,
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: string,
    tshirt?: string,
    eventId?: number | null,
    createdAt?: Date | null,
    timeSlot?: TimeSlotModel,
    nbParticipants?: number,
    groupName?: string,
    eventName?: string,
    validationStatus?: ParticipationStatus,
    eventTimeZone?: string,
  } = {}) {
    this.id = id;
    this.timeSlotId = timeSlotId;
    this.volunteerId = volunteerId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.eventId = eventId;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.tshirt = tshirt;
    this.createdAt = createdAt;
    this.nbParticipants = nbParticipants;
    this.groupName = groupName;
    this.eventName = eventName;
    this.validationStatus = validationStatus;

    if (timeSlot instanceof TimeSlotModel) {
      this.timeSlot = timeSlot;
    } else {
      (timeSlot as any).startTime = DateTime.fromJSDate((timeSlot as any).startTime).setZone(eventTimeZone);
      (timeSlot as any).endTime = DateTime.fromJSDate((timeSlot as any).endTime).setZone(eventTimeZone);
      this.timeSlot = new TimeSlotModel(timeSlot);
    }
  }

  public getTimeSlotIdsForCurrentDay(eventDay: EventDayModel, inEvent: EventModel): TimeSlotModel[] {

    const result: TimeSlotModel[] = [];
    const slotId = this.timeSlot.id === null ? this.timeSlotId : this.timeSlot.id;

    if (slotId) {
      const slot = inEvent.slotWithId(slotId);

      if (slot) {
        if (eventDay.hasInRange(slot.startTime, slot.endTime)) {
          result.push(slot);
        }
      }
    }
    return result;
  }

  public get duration() {
    return this.timeSlot.duration * this.nbParticipants;
  }
}
