import { FieldModel } from "./FieldModel";

export class FormModel {
  public id: number | null;
  public fields: FieldModel[];

  constructor({
    id = null,
    fields = [],
  }: {
    id?: number | null;
    fields?: FieldModel[];
  } = {}) {
    this.id = id;
    this.fields = fields.map((field) =>
      field instanceof FieldModel ? field : new FieldModel(field)
    );
  }

  /**
   * Sérialisation vers un format JSON pour l'API
   */
  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      fields: this.fields.map((field) => field.toJSON()),
    };
  }

  /**
   * Ajoute un champ au formulaire
   */
  public addField(field: FieldModel): void {
    const newField = field instanceof FieldModel ? field : new FieldModel(field);
    this.fields.push(newField);
  }

  /**
   * Supprime un champ du formulaire par son ID
   */
  public removeFieldById(fieldId: number): void {
    this.fields = this.fields.filter((field) => field.id !== fieldId);
  }

  /**
   * Recherche un champ par son ID
   */
  public getFieldById(fieldId: number): FieldModel | undefined {
    return this.fields.find((field) => field.id === fieldId);
  }
}
