import { CampaignAnswerModel } from './CampaignAnswerModel';
import { VolunteerModel } from './VolunteerModel';

export class CampaignRegistrationModel {
  public id: number | null = null;
  public volunteer: VolunteerModel;
  public campaignId: number | null = null;
  public createdAt: Date | null = null;
  public modifiedAt: Date | null = null;
  public customAttributeValues: { attribute: number; participation: number | null; value: number | string | boolean | Date }[] = [];
  public isSelectedOnList = false;

  constructor({
    id = null,
    volunteer = new VolunteerModel(),
    campaignId = null,
    createdAt = null,
    modifiedAt = null,
    customAttributeValues = [],
  }: {
    id?: number | null,
    volunteer?: VolunteerModel,
    campaignId?: number | null,
    createdAt?: Date | null,
    modifiedAt?: Date | null,
    customAttributeValues?: { attribute: number; participation: number | null; value: number | string | boolean | Date }[],
  } = {}) {
    this.id = id;
    this.campaignId = campaignId;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;

    this.customAttributeValues = customAttributeValues;

    if (volunteer instanceof VolunteerModel) {
      this.volunteer = volunteer;
    } else {
      this.volunteer = new VolunteerModel(volunteer);
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      volunteer: this.volunteer,
      campaignId: this.campaignId,
      createdAt: this.createdAt,
      modifiedAt: this.modifiedAt,
    };
  }
}

