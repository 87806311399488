import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { OrganizerAPI } from '@/apis';
import { CampaignModel, CampaignStatus } from '@/models/CampaignModel';
import { CampaignAnswerModel } from '@/models/CampaignAnswerModel';
import { CampaignRegistrationModel } from '@/models/CampaignRegistrationModel';
import { VolunteerModel } from '@/models/VolunteerModel';

export class CampaignService {

  private static internal: CampaignService = new CampaignService(OrganizerAPI.instance);

  public static get instance(): CampaignService {
    return CampaignService.internal;
  }

  constructor(private api: AxiosInstance) { }

  // CHECKED !
  public retrieveCampaigns(organizationId: number): Promise<CampaignModel[]> {
    return this.api.get<CampaignModel[]>(`/campaigns/`, {
      params: {
        organizationId,
      },
    }).then((response: AxiosResponse<CampaignModel[]>) => {
      return response.data.map((d: any): CampaignModel => new CampaignModel(d));
    });
  }

  public retreivePublicCampaigns(organizationId: number): Promise<CampaignModel[]> {
    return this.api.get<CampaignModel[]>(`/organization/${organizationId}/public-campaigns/`, {
    }).then((response: AxiosResponse<CampaignModel[]>) => {
      return response.data.map((d: any): CampaignModel => new CampaignModel(d));
    });
  }

  public retrieveCampaign(campaignId: string): Promise<CampaignModel> {
    return this.api.get<CampaignModel>(`/campaigns/${campaignId}/`, {
    }).then((response: AxiosResponse<CampaignModel>) => {
      return new CampaignModel(response.data);
    });
  }

  public retreiveRegistrations(id: string): Promise<CampaignRegistrationModel[]> {
    return this.api.get<CampaignRegistrationModel[]>(`/campaigns/${id}/registrations/`, {
    }).then((response: AxiosResponse<CampaignRegistrationModel[]>) => {
      return response.data.map((d: any): CampaignRegistrationModel => new CampaignRegistrationModel(d));
    }).catch((error) => {
      throw error;
    });
  }


  public createCampaign(campaign: CampaignModel): Promise<CampaignModel> {
    const formData = new FormData();

    for (const key in campaign.toJSON()) {
      if (campaign.hasOwnProperty(key)) {
        formData.append(key, campaign.toJSON()[key]);
      }
    }
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
      data: formData,
    };

    return this.api.post<CampaignModel>(
      `/campaigns/`,
      formData,
    ).then((response: AxiosResponse<CampaignModel>) => {
      return new CampaignModel(response.data);
    });
  }

  public updateCampaign(campaign: CampaignModel): Promise<CampaignModel> {
    const formData = new FormData();

    for (const key in campaign.toJSON()) {
      if (campaign.hasOwnProperty(key)) {
        formData.append(key, campaign.toJSON()[key]);
      }
    }
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
      data: formData,
    };
    return this.api.put<CampaignModel>(
      `/campaigns/${campaign.id}/`,
      formData,
      campaign.toJSON()).then((response: AxiosResponse<CampaignModel>) => {
        return new CampaignModel(response.data);
      });
  }

  public partialUpdate(campaignId: string, body: any): Promise<void> {
    const formData = new FormData();

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
      data: formData,
    };
    return this.api.patch<CampaignModel>(
      `/campaigns/${campaignId}/`,
      formData,
      body).then((response: AxiosResponse) => {
        return response.data;
      });
  }

  public delete(campaign: CampaignModel): Promise<CampaignModel> {
    return this.api.delete(`/campaigns/${campaign.id}/`).then((response: AxiosResponse) => {
      return campaign;
    });
  }

  public publicRetrieveCampaign(id: string): Promise<CampaignModel> {
    return this.api.get<CampaignModel>(`/public-campaigns/${id}/`).then((response: AxiosResponse<CampaignModel>) => {
      return new CampaignModel(response.data);
    }).catch((error) => {
      throw error;
    });
  }


  public deleteRegistration(id: string): Promise<AxiosResponse> {
    return this.api.delete<CampaignModel>(`/campaign-registrations/${id}/`)
      .then((response: AxiosResponse) => {
        return response;
      }).catch((error) => {
        throw error;
      });
  }
}
