import i18n from '@/i18n';

export class ConfigurationModel {

  public static empty() {
    return new ConfigurationModel();
  }

  public displayNameOpts = [
    { text: i18n.t('vol_display_name_nothing'), value: 'nothing' },
    { text: i18n.t('vol_display_name_full'), value: 'full_name' },
    { text: i18n.t('vol_display_name_abbreviated'), value: 'last_name_abbreviated' },
  ];

  public volunteerNameDisplay: string;
  public sendMeCopyOfRegistration: boolean;
  public registrationEmailText: string;
  public enableGroupRegistrations: boolean;
  public hideFullSlots: boolean;
  public allowPublicTimeSlotCreation: boolean;
  public enableRegistrations: boolean;
  public showPastSlots: boolean;
  public colorFree: string;
  public colorFull: string;
  public closePositionByDefault: boolean;

  constructor({
    volunteerNameDisplay = 'nothing',
    allowPublicTimeSlotCreation = false,
    enableRegistrations = true,
    showPastSlots = false,
    enableGroupRegistrations = false,
    sendMeCopyOfRegistration = false,
    hideFullSlots = false,
    closePositionByDefault = false,
    registrationEmailText = 'Hello [[volunteer_name]],\n\n\
Merci pour votre inscription à [[event_name]] !\n\n\
[[registration_resume]]\n\n\
En cas de questions, vous pouvez vous adresser à [[responsable_name]] par email : [[responsable_email]].\n\n\
Merci, sans vous, nous ne pourrions mettre sur pied notre manifestation. :-)\n\n',
    colorFree = '#4fd68e',
    colorFull = '#d65f4f',
  }: {
    volunteerNameDisplay?: string,
    allowPublicTimeSlotCreation?: boolean,
    enableRegistrations?: boolean,
    showPastSlots?: boolean,
    enableGroupRegistrations?: boolean,
    sendMeCopyOfRegistration?: boolean,
    hideFullSlots?: boolean,
    closePositionByDefault?: boolean,
    registrationEmailText?: string;
    colorFree?: string,
    colorFull?: string,
  } = {}) {

    this.volunteerNameDisplay = volunteerNameDisplay;
    this.enableRegistrations = enableRegistrations;
    this.showPastSlots = showPastSlots;
    this.closePositionByDefault = closePositionByDefault;
    this.enableGroupRegistrations = enableGroupRegistrations;
    this.sendMeCopyOfRegistration = sendMeCopyOfRegistration;
    this.registrationEmailText = registrationEmailText;
    this.hideFullSlots = hideFullSlots;
    this.allowPublicTimeSlotCreation = allowPublicTimeSlotCreation;
    this.colorFree = colorFree;
    this.colorFull = colorFull;
  }

  public toJSON(): any {
    return {
      allowPublicTimeSlotCreation: this.allowPublicTimeSlotCreation,
      enableRegistrations: this.enableRegistrations,
      showPastSlots: this.showPastSlots,
      volunteerNameDisplay: this.volunteerNameDisplay,
      enableGroupRegistrations: this.enableGroupRegistrations,
      sendMeCopyOfRegistration: this.sendMeCopyOfRegistration,
      hideFullSlots: this.hideFullSlots,
      registrationEmailText: this.registrationEmailText,
      colorFree: this.colorFree,
      colorFull: this.colorFull,
      closePositionByDefault: this.closePositionByDefault,
    };
  }

  get displayVolunteer() {
    return this.volunteerNameDisplay !== 'nothing';
  }

}
