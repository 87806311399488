import i18n from "@/i18n";

export enum AttributeType {
  TEXT = "text",
  TEXT_MULTILINE = "text_multiline",
  INT = "int",
  DATE = "date",
  ENUM = "enum",
  ENUM_MULTIPLE = "enum_multiple",
  BOOL = "bool",
}

export const AttributeTypeLabels: Record<AttributeType, string> = {
  [AttributeType.TEXT]: i18n.t('text') as string,
  [AttributeType.TEXT_MULTILINE]: i18n.t('text_multiline') as string,
  [AttributeType.INT]: i18n.t('int') as string,
  [AttributeType.DATE]: i18n.tc('date', 1) as string,
  [AttributeType.ENUM]: i18n.t('enum') as string,
  [AttributeType.ENUM_MULTIPLE]: i18n.t('enum_multiple') as string,
  [AttributeType.BOOL]: i18n.t('bool') as string,
};


export enum AttributeToModelType {
  VOLUNTEER = "volunteer",
  EVENT = "event",
  CAMPAIGN = "campaign",
}

export enum BaseAttributeType {
  ADDRESS = "address",
  CITY = "city",
  POSTCODE = "postcode",
  COUNTRY = "country",
  PHONE = "phone",
  BIRTHDATE = "birthdate",
}

export const BaseAttributeLabels: { [key in BaseAttributeType]: string } = {
  [BaseAttributeType.PHONE]: i18n.t('phone') as string,
  [BaseAttributeType.BIRTHDATE]: i18n.t('birthdate') as string,
  [BaseAttributeType.ADDRESS]: i18n.t('address') as string,
  [BaseAttributeType.POSTCODE]: i18n.t('npa') as string,
  [BaseAttributeType.CITY]: i18n.t('town') as string,
  [BaseAttributeType.COUNTRY]: i18n.t('country') as string,
};

export class AttributeModel {

  static getBaseAttributeType(): { id: BaseAttributeType; name: string }[] {
    return Object.entries(BaseAttributeLabels).map(([key, label]) => ({
      id: key as BaseAttributeType,
      name: label,
    }));
  }

  public id: number | BaseAttributeType | null;
  public name: string;
  public valueType: AttributeType;
  public event: number | null;
  public campaign: string | null;
  public onRegistration: boolean;
  public options: { id: number | null; option: string }[];

  constructor({
    id = null,
    name = '',
    valueType = AttributeType.TEXT,
    event = null,
    campaign = null,
    options = [],
    onRegistration = false,
  }: {
    id?: number | BaseAttributeType | null;
    name?: string;
    valueType?: AttributeType;
    event?: number | null;
    campaign?: string | null;
    options?: { id: number | null; option: string }[];
    onRegistration?: boolean;
  } = {}) {
    this.id = id;
    this.name = name;
    this.valueType = valueType;
    this.event = event;
    this.campaign = campaign;
    this.options = options.map((opt) => ({
      id: opt.id,
      option: opt.option,
    }));
    this.onRegistration = onRegistration;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      valueType: this.valueType,
      event: this.event,
      campaign: this.campaign,
      options: this.options,
      onRegistration: this.onRegistration,
    };
  }


  public get attributeToModelType(): AttributeToModelType {
    if (this.event) {
      return AttributeToModelType.EVENT;
    } else if (this.campaign) {
      return AttributeToModelType.CAMPAIGN;
    } else {
      return AttributeToModelType.VOLUNTEER;
    }
  }
}
