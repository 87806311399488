import i18n from "@/i18n";
import { AttributeModel, AttributeType, AttributeToModelType } from "./AttributeModel";

/**
 * Représente un champ pouvant être lié à un bénévole, un événement, une participation ou une campagne.
 */
export class FieldModel {
  public id: number | null;
  public attribute: AttributeModel;
  public isRequired: boolean;
  public label: string;
  public description: string;
  public parentFieldId: number | null;
  public condition: { targetAttribute: number | null; targetOption: number | null } | null;
  public customAttribute: number | null;
  public baseAttribute: string | null;

  constructor({
    id = null,
    attribute = new AttributeModel(),
    isRequired = false,
    label = "",
    description = "",
    parentFieldId = null,
    condition = null,
    customAttribute = null,
    baseAttribute = null,
  }: Partial<FieldModel> & { attribute?: AttributeModel | Partial<AttributeModel> } = {}) {
    this.id = id;
    this.attribute =
      attribute instanceof AttributeModel
        ? attribute
        : new AttributeModel(attribute);
    this.customAttribute = customAttribute;
    this.baseAttribute = baseAttribute;
    this.isRequired = isRequired;
    this.label = label;
    this.description = description;
    this.parentFieldId = parentFieldId;
    this.condition = condition ?? { targetAttribute: null, targetOption: null };
  }

  public toJSON(): Record<string, any> {
    const json: Record<string, any> = {
      id: this.id,
      isRequired: this.isRequired,
      label: this.label,
      type: this.attribute.attributeToModelType,
      description: this.description,
      attribute: this.attribute,
    };

    if (this.condition && this.condition?.targetAttribute !== null) {
      json.condition = {
        targetAttribute: this.condition.targetAttribute,
        targetOption: this.condition.targetOption
      };
    }
    if (this.customAttribute != null) {
      json.customAttribute = this.customAttribute;
    } else {
      json.baseAttribute = this.baseAttribute;
    }

    return json;
  }

  public get isWithOptions(): boolean {
    return (this.attribute.valueType === AttributeType.ENUM ||
      this.attribute.valueType === AttributeType.ENUM_MULTIPLE);
  }

  public get typeLabel(): string {
    switch (this.attribute.attributeToModelType) {
      case AttributeToModelType.VOLUNTEER:
        return i18n.tc("dashboard_vol", 1) as string;
      case AttributeToModelType.EVENT:
        if (this.attribute && this.attribute.onRegistration) {
          return i18n.tc("registrations", 1) as string;
        }
        return i18n.tc("event", 1) as string;
      case AttributeToModelType.CAMPAIGN:
        return i18n.tc("campaign", 1) as string;
      default:
        return "-";
    }
  }

  private get badgeColor(): string {
    switch (this.attribute.attributeToModelType) {
      case AttributeToModelType.VOLUNTEER:
        return "primary";
      case AttributeToModelType.EVENT:
        if (this.attribute && this.attribute.onRegistration) {
          return "secondary";
        }
        return "success";
      case AttributeToModelType.CAMPAIGN:
        return "warning";
      default:
        return "secondary";
    }
  }

  public get type(): AttributeToModelType {
    return this.attribute.attributeToModelType;
  }
}
