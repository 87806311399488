import { LicenseType, OrganizationModel } from "@/models/OrganizationModel";
import { UserModel } from "@/models/UserModel";
import { AuthenticationService } from "@/services/";
import { OrganizationService } from "./OrganizationService";
import i18n from '@/i18n';

const IDMOTOCROSS = 109;
const IDVENOGE = 56;
const IDBIBLIO = 76;
const IDPALEO = 40;
const IDCHAMPAGNESALLE = 120;
const IDEHRO = 1;
const IDESTIVALE = 101;
const IDFIFAD = 98;
const IDPDG = 149;
const IDALETSCH = 171;
const IDCRAN = 172;
const IDCHAMPERY = 158;
const IDCARIBANA = 174;
const IDGIVRINS = 144;
const IDSCT = 159;
const IDVENOGE_BADGE = 194;
const IDVS25 = 230;

export class PermissionLib {

  public get user(): UserModel {
    if (AuthenticationService.instance.authenticatedUser) {
      return AuthenticationService.instance.authenticatedUser;
    } else {
      return new UserModel();
    }
  }

  public volunteerToken = '';

  public get currentOrganization(): OrganizationModel {
    if (this.user.token !== '' && this.user.currentOrganization) {
      return this.user.currentOrganization;
    } else {
      return new OrganizationModel();
    }
  }

  setCurrentOrganization(newOrganizationId: number): Promise<any> {
    return AuthenticationService.instance.setOrganization(newOrganizationId).then((response) => {
      this.user.activeOrganization = response.id;
      return response;
    }).catch((error) => {
      throw error;
    });
  }

  get publicOrganization(): OrganizationModel | null {
    if (AuthenticationService.instance.publicOrganization) {
      return AuthenticationService.instance.publicOrganization;
    }
    return null;
  }

  public get isCorporate() {
    if (this.currentOrganization) {
      return this.currentOrganization.licenseType === LicenseType.Corporate ||
        this.currentOrganization.id === IDEHRO;
    }
    return false;
  }

  public get isPublicCorporate() {
    if (this.publicOrganization) {
      return this.publicOrganization.licenseType === LicenseType.Corporate;
    }
    return false;
  }

  public get isLight() {
    return this.currentOrganization && this.currentOrganization.licenseType === LicenseType.Light;
  }
  public get isClub() {
    return this.currentOrganization && this.currentOrganization.licenseType === LicenseType.Club;
  }

  public get isPro() {
    if (this.currentOrganization) {
      return this.currentOrganization.licenseType === LicenseType.Pro ||
        this.currentOrganization.id === IDEHRO;
    }
  }

  public get publicEnableVouchers() {
    return this.publicOrganization && this.publicOrganization.enableVouchers;
  }

  public get publicEnableShop() {
    return this.publicOrganization && this.publicOrganization.enableShop;
  }

  public get enableShop() {
    return this.currentOrganization && this.currentOrganization.enableShop;
  }

  public get enableBadges() {
    return this.currentOrganization && this.currentOrganization.enableBadges;
  }

  public get enableVouchers() {
    return this.currentOrganization && this.currentOrganization.enableVouchers;
  }

  public get enableCampaigns() {
    return this.currentOrganization && this.currentOrganization.enableCampaigns;
  }

  public get enableWeezevent() {
    return this.currentOrganization && this.currentOrganization.enableWeezevent;
  }

  public get enableParticipationsValidation() {
    return this.currentOrganization && this.currentOrganization.enableParticipationsValidation;
  }

  // CLIENTS HACKS BACKOFFICE
  public get isHackBIBLIO() {
    return this.currentOrganization && this.currentOrganization.id === IDBIBLIO;
  }

  public get isHackEstivale() {
    return this.currentOrganization && this.currentOrganization.id === IDESTIVALE;
  }

  public get isHackSCT() {
    return this.currentOrganization && this.currentOrganization.id === IDSCT;
  }

  public get isHackCHAMPAGNESALLES() {
    return this.currentOrganization && this.currentOrganization.id === IDCHAMPAGNESALLE;
  }

  public get isHackVALAIS25() {
    return this.currentOrganization && this.currentOrganization.id === IDVS25;
  }

  // CLIENTS PUBLIC HACK

  public get isPublicHackVALAIS25() {
    return this.publicOrganization && (
      this.publicOrganization.id === IDALETSCH ||
      this.publicOrganization.id === IDCRAN ||
      this.publicOrganization.id === IDCHAMPERY
    );
  }

  public get isPublicHackVENOGE() {
    return this.publicOrganization && this.publicOrganization.id === IDVENOGE;
  }

  public get isPublicHackBIBLIO() {
    return this.publicOrganization && this.publicOrganization.id === IDBIBLIO;
  }

  public get isPublicHackCHAPAGNESALLES() {
    return this.publicOrganization && this.publicOrganization.id === IDCHAMPAGNESALLE;
  }

  public get isPublicHackESTIVALE() {
    return this.currentOrganization && this.currentOrganization.id === IDESTIVALE;
  }

  public get isPublicHackCARIBANA() {
    return this.currentOrganization && this.currentOrganization.id === IDCARIBANA;
  }

  public get isPublicHackVenogeBadge() {
    return this.currentOrganization && this.currentOrganization.id === IDVENOGE_BADGE;
  }


}
