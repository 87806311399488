export enum CampaignStatus {
  Draft = 'draft',
  Published = 'published',
  Closed = 'closed',
  Archived = 'archived',
}

export class CampaignModel {

  public static empty() {
    return new CampaignModel();
  }

  public id: string | null = null;
  public organization: number | null = null;
  public name: string;
  public description: string;
  public contactName: string;
  public contactEmail: string;
  public logo: string;
  public base64Logo: string;
  public isPublic: boolean;
  public registrationEmailText: string;
  public status: CampaignStatus;
  public nbRegistrations: number;
  public token: string;
  public showOnHomeCalendar: boolean;

  public timeZone: string = '';

  constructor({
    id = null,
    organization = null,
    token = '',
    name = '',
    description = '',
    contactName = '',
    contactEmail = '',
    logo = '',
    timeZone = 'Europe/Zurich',
    base64Logo = '',
    status = CampaignStatus.Draft,
    nbRegistrations = 0,
    showOnHomeCalendar = false,
    isPublic = false,
    registrationEmailText = 'Hello [[volunteer_name]],\n\n\
Merci pour votre inscription à [[campaign_name]] !\n\n\
[[campaign_registration_summary]]\n\n\
En cas de questions, vous pouvez vous adresser à [[campaign_coordinator_name]] par email : [[campaign_coordinator_email]].\n\n\
Merci, sans vous, nous ne pourrions mettre sur pied notre manifestation. :-)\n\n',
  }: {
    id?: string | null,
    organization?: number | null,
    token?: string,
    name?: string,
    description?: string,
    contactName?: string,
    contactEmail?: string,
    fields?: any[],
    logo?: string,
    base64Logo?: string,
    status?: CampaignStatus,
    nbRegistrations?: number
    isPublic?: boolean,
    timeZone?: string,
    registrationEmailText?: string,
    showOnHomeCalendar?: boolean,
  } = {}) {
    this.id = id;
    this.organization = organization;
    this.token = token;
    this.name = name;
    this.logo = logo;
    this.base64Logo = base64Logo;
    this.status = status;
    this.nbRegistrations = nbRegistrations;
    this.description = description;
    this.contactName = contactName;
    this.contactEmail = contactEmail;
    this.isPublic = isPublic;
    this.timeZone = timeZone;
    this.showOnHomeCalendar = showOnHomeCalendar;
    this.registrationEmailText = registrationEmailText;
  }

  public toJSON(): any {
    return {
      id: this.id,
      organization: this.organization,
      token: this.token,
      name: this.name,
      description: this.description,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      base64Logo: this.base64Logo,
      status: this.status,
      isPublic: this.isPublic,
      registrationEmailText: this.registrationEmailText,
      showOnHomeCalendar: this.showOnHomeCalendar,
      timeZone: this.timeZone,
    };
  }
}
