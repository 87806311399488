import { OrganizerAPI } from '@/apis';
import { AttributeModel, AttributeType, AttributeToModelType } from '@/models/Form/AttributeModel';
import { FieldModel } from '@/models/Form/FieldModel';
import { FormModel } from '@/models/Form/FormModel';
import { AxiosInstance, AxiosResponse } from 'axios';

export class FormService {
  private static internal: FormService = new FormService(OrganizerAPI.instance);

  public static get instance(): FormService {
    return FormService.internal;
  }

  constructor(private api: AxiosInstance) { }

  // Volunteer Attributes
  public getVolunteerAttributes(): Promise<AttributeModel[]> {
    return this.api
      .get<AttributeModel[]>(`/custom-volunteer-attributes/`)
      .then((response: AxiosResponse<AttributeModel[]>) => {
        return response.data.map((d: any): AttributeModel => new AttributeModel(d));
      });
  }

  public async getVolunteerAttributesWithBase(): Promise<AttributeModel[]> {
    const customAttributes = await this.getVolunteerAttributes();

    // Récupérer les baseAttributes
    const baseAttributes = AttributeModel.getBaseAttributeType();

    // Convertir les baseAttributes en AttributeModel
    const baseAttributeModels = baseAttributes.map(
      (baseAttr) =>
        new AttributeModel({
          id: baseAttr.id, // BaseAttributeType ID
          name: baseAttr.name, // Nom de l'attribut
          valueType: AttributeType.TEXT, // Type TEXT par défaut pour les BaseAttributes
        })
    );

    return [...baseAttributeModels, ...customAttributes];
  }

  public postVolunteerAttributes(attribute: AttributeModel): Promise<AttributeModel> {
    return this.api
      .post(`/custom-volunteer-attributes/`, attribute.toJSON())
      .then((response: AxiosResponse) => new AttributeModel(response.data));
  }

  public putVolunteerAttributes(attribute: AttributeModel): Promise<AttributeModel> {
    return this.api
      .put(`/custom-volunteer-attributes/${attribute.id}/`, attribute.toJSON())
      .then((response: AxiosResponse) => new AttributeModel(response.data));
  }

  public deleteVolunteerAttributes(fieldId: number): Promise<void> {
    return this.api
      .delete(`/custom-volunteer-attributes/${fieldId}/`)
      .then(() => undefined);
  }

  // Event Attributes
  public getEventAttributes(eventId: number): Promise<AttributeModel[]> {
    return this.api
      .get<AttributeModel[]>(`/events/${eventId}/custom-volunteer-attributes/`)
      .then((response: AxiosResponse<AttributeModel[]>) => {
        return response.data.map((d: any): AttributeModel => new AttributeModel(d));
      });
  }
  public postEventAttributes(eventId: number, attribute: AttributeModel): Promise<AttributeModel> {
    return this.api
      .post(`/events/${eventId}/custom-volunteer-attributes/`, attribute.toJSON())
      .then((response: AxiosResponse) => new AttributeModel(response.data));
  }

  // Campaign Attributes
  public getCampaignAttributes(cmpId: string): Promise<AttributeModel[]> {
    return this.api
      .get<AttributeModel[]>(`/campaigns/${cmpId}/custom-volunteer-attributes/`)
      .then((response: AxiosResponse<AttributeModel[]>) => {
        return response.data.map((d: any): AttributeModel => new AttributeModel(d));
      });
  }
  public postCampaignAttributes(cmpId: string, attribute: AttributeModel): Promise<AttributeModel> {
    return this.api
      .post(`/campaigns/${cmpId}/custom-volunteer-attributes/`, attribute.toJSON())
      .then((response: AxiosResponse) => new AttributeModel(response.data));
  }

  // Event Form
  public getEventForm(eventId: number): Promise<FormModel> {
    return this.api
      .get<FormModel>(`/events/${eventId}/form/`)
      .then((response: AxiosResponse<FormModel>) => new FormModel(response.data));
  }
  public getCampaignForm(cmpId: string): Promise<FormModel> {
    return this.api
      .get<FormModel>(`/campaigns/${cmpId}/form/`)
      .then((response: AxiosResponse<FormModel>) => new FormModel(response.data));
  }

  public async getDeepForm(
    id: string,
    type: AttributeToModelType
  ): Promise<FormModel> {
    try {
      // 1. Récupérer le formulaire brut selon le type
      const rawForm = type === AttributeToModelType.EVENT
        ? await this.getEventForm(Number(id))
        : await this.getCampaignForm(id);

      // 2. Récupérer les attributs custom selon le type
      const customAttributes =
        type === AttributeToModelType.EVENT
          ? await this.getEventAttributes(Number(id))
          : await this.getCampaignAttributes(id);

      // 3. Récupérer les attributs custom + base pour les bénévoles
      const customVolunteerAttributes = await this.getVolunteerAttributesWithBase();

      // 4. Construire un map des attributs pour accès rapide
      const attributeMap = new Map<number | string, AttributeModel>();

      // Ajouter les attributs custom (événement ou campagne)
      customAttributes.forEach((attr) => {
        attributeMap.set(attr.id!, new AttributeModel(attr));
      });

      // Ajouter les attributs custom + base bénévoles
      customVolunteerAttributes.forEach((attr) => {
        attributeMap.set(attr.id!, new AttributeModel(attr));
      });

      // 5. Construire les champs enrichis
      const fields = rawForm.fields.map((field: any) => {
        const attribute = field.customAttribute
          ? attributeMap.get(field.customAttribute)
          : attributeMap.get(field.baseAttribute);

        if (!attribute) {
          console.warn(`Attribut introuvable pour le champ ${field.id}`);
          return null;
        }
        const f = new FieldModel(field);
        f.attribute = attribute;
        return f;
      }).filter((field) => field !== null);

      // 6. Retourner le formulaire complet
      return new FormModel({
        id: rawForm.id,
        fields: fields as FieldModel[],
      });
    } catch (error) {
      console.error("Erreur lors de la récupération du DeepForm :", error);
      throw error;
    }
  }

  public postEventForm(eventId: number, form: FormModel): Promise<FormModel> {
    return this.api
      .post(`/events/${eventId}/form/`, {
        fields: form.fields,
      })
      .then((response: AxiosResponse) => new FormModel(response.data));
  }

  public putEventForm(eventId: number, form: FormModel): Promise<FormModel> {
    return this.api
      .put(`/events/${eventId}/form/`, {
        fields: form.fields.map((field) => ({
          id: field.id,
          customAttribute: field.id,
          label: field.label,
          description: field.description,
          isRequired: field.isRequired,
        })),
      })
      .then((response: AxiosResponse) => new FormModel(response.data));
  }

  public postCampaignForm(cmpId: string, form: FormModel): Promise<FormModel> {
    return this.api
      .post(`/campaigns/${cmpId}/form/`, {
        fields: form.fields,
      })
      .then((response: AxiosResponse) => new FormModel(response.data));
  }
}
