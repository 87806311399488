import { DateUtils } from '@/utils/date';
import * as Utils from '@/assets/js/Utils.js';
import { CustomAnswerModel } from './CustomAnswerModel';
import { EventModel } from './EventModel';
import { ParticipationModel } from './ParticipationModel';
import { DateTime, Interval } from 'luxon';
import { PositionModel } from './PositionModel';
import i18n from '@/i18n';
import { RewardCategoryModel } from './RewardCategoryModel';
import { EventService } from '@/services';

export class TimeSlotModel {

  // public static _nbr = 0;

  public static create(startDate: DateTime, endDate: DateTime, nbrVol: number) {
    return new TimeSlotModel({
      startTime: startDate,
      endTime: endDate,
      requiredVolunteerCount: nbrVol,
    });
  }

  public static copy(slot: TimeSlotModel) {
    return TimeSlotModel.create(slot.startTime, slot.endTime, slot.requiredVolunteerCount);
  }

  public static clone(slot: TimeSlotModel) {
    const TS = TimeSlotModel.copy(slot);
    TS.id = slot.id;
    TS.description = slot.description;
    TS.isPrivate = slot.isPrivate;
    TS.series = slot.series;
    TS.rewardCategory = slot.rewardCategory;
    TS.color = slot.color;
    TS.icon = slot.icon;
    TS.hourlyRate = slot.hourlyRate;
    return TS;
  }

  public copyValueFrom(slot: TimeSlotModel) {
    this.description = slot.description;
    this.startTime = slot.startTime;
    this.endTime = slot.endTime;
    this.isPrivate = slot.isPrivate;
    this.requiredVolunteerCount = slot.requiredVolunteerCount;
    this.rewardCategory = slot.rewardCategory;
  }

  // TS
  public id: number | null = null;
  public description: string;
  public startTime: DateTime;
  public endTime: DateTime;
  public requiredVolunteerCount: number;
  public isPrivate: boolean;
  public duration: number = 0;
  public createdAt: Date | null = null;
  public recurrence: string = '';
  public series: number | null = null;

  // Reward
  public rewardCategory: number | null = null;
  public hourlyRate: string = '';
  public _icon: string = '';

  public set icon(link: string) {
    this._icon = link;
  }
  public get icon() {
    if (this._icon && this._icon !== '') {
      return this._icon;
    }
    return '';
  }

  // FOR DISPLAY
  public currentYIndex: number = 0;

  // Ref participations
  public participations: ParticipationModel[] = [];
  public participationId: number | null = null;
  public nbParticipants: number = 0;
  public participants: string[] = [];

  // Printing et ref
  public positionId: number;
  public position: PositionModel | null = null;
  public positionName: string = '';

  // Ref Event
  public eventId: number | null = null;
  public eventName: string = '';
  public event: EventModel | null = null;

  public isSelectedOnList = false;
  public startBeforeRange = false;
  public endBeforeRange = false;

  constructor({
    id = null,
    description = '',
    startTime = DateTime.fromMillis(0),
    endTime = DateTime.fromMillis(0),
    requiredVolunteerCount = 0,
    isPrivate = false,
    nbParticipants = 0,
    participants = [],
    duration = 0,
    series = null,
    position = -1,
    positionName = '',
    rewardCategory = null,
  }: {
    id?: number | null,
    description?: string,
    startTime?: DateTime,
    endTime?: DateTime,
    requiredVolunteerCount?: number,
    isPrivate?: boolean,
    nbParticipants?: number,
    participants?: string[],
    duration?: number,
    series?: number | null,
    position?: number,
    positionName?: string,
    rewardCategory?: number | null,
  } = {}) {
    this.id = id;
    this.description = description;
    this.startTime = startTime;
    this.endTime = endTime;
    this.isPrivate = isPrivate;
    this.requiredVolunteerCount = requiredVolunteerCount;
    this.nbParticipants = nbParticipants;
    this.participants = participants;
    this.duration = duration;
    this.series = series;
    this.positionId = position;
    this.positionName = positionName;
    this.rewardCategory = rewardCategory;
  }

  public toJSON(): any {
    const json: any = {
      id: this.id,
      description: this.description,
      startTime: this.startTime.toISO(),
      endTime: this.endTime.toISO(),
      requiredVolunteerCount: this.requiredVolunteerCount,
      isPrivate: this.isPrivate,
      series: this.series,
      position: this.positionId,
      rewardCategory: this.rewardCategory,
    };
    if (this.recurrence !== '') {
      json.recurrence = this.recurrence;
    }
    if (this.position) {
      json.position = this.position.id;
    }
    return json;
  }

  public get hours() {
    return Interval.fromDateTimes(this.startTime, this.endTime).toDuration('hours').hours;
  }
  public get hoursMinutes() {
    return Interval.fromDateTimes(this.startTime, this.endTime).toDuration(['hours', 'minutes']).toObject();
  }

  public get points() {
    if (this.hourlyRate !== '') {
      return this.hours * Number(this.hourlyRate);
    }

    return 0;
  }

  public get printName() {
    if (this.position) {
      return this.position.name;
    } else if (this.positionName != '') {
      return this.positionName;
    } else {
      return "Error";
    }
  }

  private iColor: string = '';
  public get color(): string {
    if (this.iColor) {
      return this.iColor;
    }
    return '';
  }

  public get listHeight() {
    return this.participants.length * 18;
  }
  public getSlotHeight(displayNames: boolean = false) {
    let base = 40;
    if (this.description !== '') {
      base = 60;
    }
    if (displayNames) {
      base = base + this.listHeight;
    }
    return base;
  }

  public set color(newValue: string) {
    this.iColor = newValue;
  }

  getRewardCategoryFromList(rewardCategories: RewardCategoryModel[]) {
    return rewardCategories.find((item) => item.id === this.rewardCategory);
  }


  public getParticipations(participations: ParticipationModel[]): ParticipationModel[] {
    return participations.filter((part) => {
      if (part.timeSlotId === -1 && part.timeSlot && part.timeSlot.id) {
        return part.timeSlot.id === this.id;
      }
      return part.timeSlotId === this.id
    });
  }

  public overlapOne(timeslots: TimeSlotModel[]): boolean {
    let result = false;

    timeslots.forEach((slot) => {
      if (slot.startTime.toMillis() < this.startTime.toMillis() && slot.endTime.toMillis() > this.endTime.toMillis()) {
        // Cas 1, le shift testé commence avant et termine après le shift source
        result = result || true;
      } else if (slot.startTime.toMillis() < this.endTime.toMillis() && slot.endTime.toMillis() > this.endTime.toMillis()) {
        // Cas 2, le shift testé commence pendant le shift source
        result = result || true;
      } else if (slot.endTime.toMillis() > this.startTime.toMillis() && slot.startTime.toMillis() < this.startTime.toMillis()) {
        // Cas 3, le shift testé termine pendant le shift source (mais commence avant)
        result = result || true;
      } else if (slot.startTime.toMillis() < this.endTime.toMillis() && slot.endTime.toMillis() > this.startTime.toMillis()) {
        // Cas 4, le shift testé commence et termine pendant le shift source
        result = result || true;
      }
    });
    return result;
  }

  public isIncludedOnRange(startDate: DateTime, endDate: DateTime): boolean {
    let result = false;

    const shiftStart = this.startTime.toMillis();
    const shiftEnd = this.endTime.toMillis();
    const rangeStart = startDate.toMillis();
    const rangeEnd = endDate.toMillis();

    this.startBeforeRange = shiftStart < rangeStart;
    this.endBeforeRange = rangeEnd < shiftEnd;

    return shiftStart < rangeEnd && shiftEnd > rangeStart /*&& shiftEnd <= rangeEnd*/;
  }

  public getCurrentParticipantNumber(participations: ParticipationModel[]): number {
    const parts = this.getParticipations(participations);
    if (parts.length > 0 && this.nbParticipants === 0) {
      this.nbParticipants = parts.map((part) => part.nbParticipants).reduce((p, n) => p + n)
    }
    return this.nbParticipants;
  }

  public get numberVolunteersRequired() {
    return this.requiredVolunteerCount;
  }
  public set numberVolunteersRequired(value: number) {
    this.requiredVolunteerCount = value;
  }

  public get timeInfo() {
    var diffInDay = this.endTime.startOf('day').diff(this.startTime.startOf('day'), 'day');
    diffInDay.toObject();
    const daysOffset = this.endTime.day - this.startTime.day;

    var formattedMinute = ("0" + this.hoursMinutes.minutes).slice(-2);

    return `${this.startTime.toFormat('HH:mm')} - ${this.endTime.toFormat('HH:mm')}` +
      (daysOffset > 1 ? ` (+${daysOffset}${i18n.t('jour_small')})` : '') +
      ` [${this.hoursMinutes.hours}h${formattedMinute}]`;
  }

  public get smallTimeInfo() {
    var diffInDay = this.endTime.startOf('day').diff(this.startTime.startOf('day'), 'day');
    diffInDay.toObject();
    const daysOffset = this.endTime.day - this.startTime.day;

    var formattedMinute = ("0" + this.hoursMinutes.minutes).slice(-2);

    return `${this.startTime.toFormat('HH:mm')} - ${this.endTime.toFormat('HH:mm')}` +
      (daysOffset > 1 ? ` (+${daysOffset}${i18n.t('jour_small')})` : '');
  }


  // INLINE FORM METHOD
  private _inlineStartDate = '';
  get inlineStartDate() {
    if (this._inlineStartDate === '') {
      this._inlineStartDate = this.startTime.toFormat('yyyy-LL-dd');
    }
    return this._inlineStartDate;
  }
  set inlineStartDate(value: string) {
    this._inlineStartDate = value;
  }

  private _inlineEndDate = '';
  get inlineEndDate() {
    if (this._inlineEndDate === '') {
      this._inlineEndDate = this.endTime.toFormat('yyyy-LL-dd');
    }
    return this._inlineEndDate;
  }
  set inlineEndDate(value: string) {
    this._inlineEndDate = value;
  }

  private _inlineStartTime = '';
  get inlineStartTime() {
    if (this._inlineStartTime === '') {
      this._inlineStartTime = this.startTime.toFormat('HH:mm');
    }
    return this._inlineStartTime;
  }
  set inlineStartTime(value: string) {
    this._inlineStartTime = value;
  }

  private _inlineEndTime = '';
  get inlineEndTime() {
    if (this._inlineEndTime === '') {
      this._inlineEndTime = this.endTime.toFormat('HH:mm');
    }
    return this._inlineEndTime;
  }
  set inlineEndTime(value: string) {
    this._inlineEndTime = value;
  }


}
