import i18n from '@/i18n';
import Vue from 'vue';
import { PermissionLib } from './services/PermissionLib';

export function v4Url(url: string): string {
  return process.env.VUE_APP_BASE_API_URL + url;
}

export function v4PublicUrl(url: string): string {
  const trimmedPublicSite = (process.env.VUE_APP_PUBLIC_SITE || '').replace(/\/+$/, '');
  return trimmedPublicSite + url;
}

export function v4VolunteerUrl(volunteerId: number): string {
  return v4Url(`/volunteers/${volunteerId}/`);
}

export function v4404rUrl(): string {
  return v4Url(`/404/`);
}

export class Nav {
  public static items(permissionLib: PermissionLib): any[] {

    const items = [];


    // Dashboard
    items.push(
      {
        name: i18n.t('dashboard'),
        url: '/dashboard',
        icon: 'icon-speedometer',
      },
    );
    // Events
    items.push(
      {
        divider: true,
      },
      {
        title: true,
        name: i18n.tc('event', 2),
        class: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: i18n.t('create_event'),
        url: '/events/new',
        icon: 'icon-plus',
      },
      {
        name: i18n.t('menu_event'),
        url: '/events/list',
        icon: 'cui-sun',
      },
      {
        name: i18n.t('menu_archive'),
        url: '/events/archive',
        icon: 'cui-moon',
      },
    );

    if (permissionLib.isCorporate || permissionLib.isPro) {
      items.push({
        name: i18n.t('calendar'),
        url: '/events/calendar',
        icon: 'icon-calendar',
      });
    }

    items.push({
      name: i18n.tc('tag', 2),
      url: v4Url('/tags/'),
      icon: 'icon-tag',
    });

    // Campaigns
    if (permissionLib.enableCampaigns) {
      items.push(
        {
          divider: true,
        },
        {
          title: true,
          name: i18n.t('menu_campaign'),
          class: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: i18n.t('menu_my_campaigns'),
          url: '/campaigns',
          icon: 'icon-rocket',
        },
      );
    }

    // Volunteers
    items.push(
      {
        divider: true,
      },
      {
        title: true,
        name: i18n.t('menu_volunteer'),
        class: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: i18n.t('menu_my_volunteers'),
        url: v4Url('/volunteers/'),
        icon: 'icon-people',
      },
      {
        name: i18n.t('menu_my_categories'),
        url: v4Url('/categories/'),
        icon: 'icon-eyeglass',
      },
      {
        name: i18n.t('menu_volunteers_import'),
        url: v4Url('/volunteers/import/'),
        icon: 'icon-cloud-upload',
      },
    );


    items.push(
      {
        name: 'Managers',
        url: '/manager/list',
        icon: 'icon-user',
      },
    );


    // Affectations
    items.push(
      {
        divider: true,
      },
      {
        title: true,
        name: i18n.t('menu_affectation'),
        class: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
    );

    if (permissionLib.enableParticipationsValidation || permissionLib.isPro || permissionLib.isCorporate) {
      items.push(
        {
          name: i18n.t('menu_validations'),
          url: v4Url('/participations/validate/'),
          icon: 'icon-check',
          badge: { 'variant': 'danger', 'text': permissionLib.currentOrganization.nbPendingValidations },
        }
      );
    }

    items.push(
      {
        name: i18n.t('menu_participations_export'),
        url: v4Url('/participations/export/'),
        icon: 'icon-cloud-download',
      },
    );

    items.push(
      {
        name: i18n.t('menu_participations_overview'),
        url: v4Url('/overview/participations/'),
        icon: 'icon-pie-chart',
      },
    );

    if (permissionLib.enableShop || permissionLib.enableVouchers) {
      // Communication
      items.push(
        {
          divider: true,
        },
        {
          title: true,
          name: i18n.t('menu_shop'),
          class: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        }
      );
      if (permissionLib.enableShop) {
        items.push(
          {
            name: i18n.t('menu_shop_config'),
            url: '/shop/config',
            icon: 'icon-speech',
          },
        );
        items.push(
          {
            name: i18n.t('menu_shop_product'),
            url: v4Url('/shop/products/'),
            icon: 'icon-basket',
          },
        );
      }

      if (permissionLib.enableVouchers) {
        items.push(
          {
            name: i18n.t('menu_voucher_list'),
            url: v4Url('/vouchers/'),
            icon: 'icon-present',
          },
          {
            name: i18n.t('menu_voucher_import'),
            url: v4Url('/vouchers/import/'),
            icon: 'icon-cloud-upload',
          }
        );
      }
    }

    // Communication
    items.push(
      {
        divider: true,
      },
      {
        title: true,
        name: i18n.t('menu_communication'),
        class: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: i18n.t('menu_com_model'),
        url: v4Url('/communications/templates/'),
        icon: 'icon-docs',
      },
      {
        name: i18n.t('menu_send_mail'),
        url: v4Url('/communications/send/'),
        icon: 'icon-speech',
      },
      {
        name: i18n.t('menu_history_mail'),
        url: v4Url('/communications/'),
        icon: 'icon-notebook',
      },
    );

    if (permissionLib.enableBadges) {
      items.push(
        {
          divider: true,
        },
        {
          title: true,
          name: i18n.t('menu_badge'),
          class: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: i18n.t('menu_com_model'),
          url: v4Url('/badges/templates/'),
          icon: 'icon-docs',
        },
      );
    }

    if (permissionLib.enableWeezevent) {
      items.push(
        {
          divider: true,
        },
        {
          title: true,
          name: i18n.t('menu_integrations'),
          class: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: 'Weezevent',
          url: v4Url('/weezevent/'),
          icon: 'icon-puzzle',
        },
      );
    }


    // Printing
    items.push(
      {
        divider: true,
      },
      {
        title: true,
        name: i18n.tc('menu_printing', 2),
        class: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: i18n.t('menu_planning'),
        url: '/action/print',
        icon: 'icon-printer',
      },
    );
    if (!permissionLib.isCorporate) {
      items.push(
        {
          name: i18n.t('menu_planning_per_volunteer'),
          url: '/action/print-ppb',
          icon: 'icon-printer',
        },
        {
          name: i18n.t('menu_bon'),
          url: '/action/print-bon',
          icon: 'icon-printer',
        },
        {
          name: i18n.t('menu_order_tshirt'),
          url: '/action/print-tshirt',
          icon: 'icon-printer',
        },
      );
    }

    // Info
    items.push(
      {
        divider: true,
      },
      {
        title: true,
        name: i18n.t('menu_info'),
        class: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: i18n.t('menu_contact'),
        url: '/infos/contact',
        icon: 'icon-speech',
      },
      {
        name: i18n.t('menu_documentation'),
        url: '/infos/doc',
        icon: 'icon-layers',
      },
      {
        name: i18n.t('menu_features'),
        url: '/infos/whatsnew',
        icon: 'icon-like',
      },
      {
        name: i18n.t('menu_vlog'),
        url: '/infos/vlog',
        icon: 'icon-camera',
      },
      {
        name: i18n.t('menu_soon'),
        url: '/infos/soon',
        icon: 'icon-like',
      },
    );
    return items;
  }
}
